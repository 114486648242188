import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function UsagePolicy() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>USAGE POLICIES</h3>
              <p>
                To ensure safe and responsible use of 99GENS LLC technologies,
                including third-party models, tools, and services, we have established
                usage policies that apply to all users. Compliance with these policies
                is required to use our technologies for legitimate purposes.
              </p>
              <p>
                In the event that we discover your product or usage of our
                technologies violates these policies, we may require necessary changes
                to be made. Repeated or serious violations may result in further
                action, including suspension or termination of your account.
              </p>
              <p>
                As we continue to learn about the use and potential misuse of our
                technologies, our policies may be subject to change. The customer
                acknowledges that their use of 99GENS LLC’s technologies is subject to
                these policies.
              </p>
              <h3>1. Platform Policy</h3>
              <p>
                Our API powers businesses across diverse sectors and technology
                platforms, including but not limited to iOS apps, websites, and Slack.
                Its simplicity enables integration into a wide array of use cases,
                subject to the use case restrictions described below. We permit the
                integration of our API into products on major technology platforms,
                app stores, and other platforms, subject to compliance with our
                policies and terms.
              </p>
              <p>
                The customer acknowledges that their use of our API is subject to
                these policies and terms, and any unauthorized use may result in
                immediate termination of access. We reserve the right to modify or
                revoke access to our API at any time for any reason, without notice.
              </p>
              <h3>2. Prohibited Activities</h3>
              <p>
                99GENS LLC strictly prohibits the use of our technologies for the
                following activities, without limitation:
              </p>
              <ul>
                <li>
                  Copyrighted, Trademarked or Identity, Image or Likeness of Public
                  Profiles
                </li>
                <li>
                  Brands which you do not own, or own rights to, Trademarked or
                  otherwise
                </li>
                <li>
                  Content which impersonates Brands, transformative or otherwise
                </li>
                <li>Activity that violates people's privacy</li>
                <li>
                  Child Sexual Abuse Material or any content that exploits or harms
                  children
                </li>
                <li>
                  Political campaigning or lobbying (without express written consent)
                </li>
                <li>Activity that has high risk of economic harm</li>
                <li>Adult content, adult industries, and dating apps</li>
                <li>Fraudulent or deceptive activity</li>
                <li>Activity that has high risk of physical harm</li>
                <li>Generation of hateful, harassing, or violent content</li>
                <li>Illegal activity</li>
              </ul>
              <p>
                We reserve the right to modify or update this list at any time,
                without prior notice.
              </p>

              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
